<template>
  <!-- 用户管理 -->
  <div class="czls">
    <!-- 顶部搜索 -->
    <el-form label-width="80px"
             ref="myform"
             size="small"
             :inline="true">

      <el-form-item label="手机号或姓名"
                    prop="keyword"
                    label-width="100px">
        <el-input v-model="queryParams.keyword"
                  placeholder="请输入手机号或姓名"
                  clearable></el-input>
      </el-form-item>

      <el-form-item label="销售姓名"
                    prop="sellerName">
        <el-input v-model="queryParams.seller"
                  placeholder="请输入销售姓名"
                  clearable></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   icon="el-icon-search"
                   size="medium"
                   @click="inquire">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- (CURD)操作按钮 -->

      <!-- 表格区域 -->
      <el-table :data="czlsList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="name"
                         align="center"
                         label="姓名">
        </el-table-column>
        <el-table-column prop="phone"
                         align="center"
                         label="手机">
        </el-table-column>
        <el-table-column prop="avatar"
                         align="center"
                         label="头像">
          <template slot-scope="scope">
            <img v-if="scope.row.avatar"
                 :src="$url+scope.row.avatar"
                 min-width="70"
                 height="70" />
          </template>
        </el-table-column>
        <el-table-column prop="age"
                         align="center"
                         label="年龄">
        </el-table-column>
        <el-table-column prop="gender"
                         align="center"
                         label="性别"
                         :formatter="sex">
        </el-table-column>
        <el-table-column prop="sellerName"
                         align="center"
                         label="渠道名">
        </el-table-column>
        <el-table-column prop="status"
                         align="center"
                         width="150"
                         label="状态">
          <template slot-scope="scope">
            <span v-if="authAttr<=1">
              <span v-if="scope.row.status==1">正常</span>
              <span v-if="scope.row.status==2">已锁定</span>

            </span>
            <span v-if="scope.row.status==0">未注册</span>
            <span v-if="scope.row.status==3">已注销</span>
            <el-switch v-if="scope.row.status!=3&&scope.row.status!=0&&authAttr>1"
                       v-model="scope.row.status"
                       active-color="#13ce66"
                       inactive-color="#ff4949"
                       :active-value="1"
                       :inactive-value="2"
                       active-text="正常"
                       inactive-text="锁定"
                       @change="updateBrandStatus(scope.row)"></el-switch>
          </template>

        </el-table-column>
        <el-table-column label="操作"
                         width="280">
          <template v-slot="{row}">
            <el-button-group>
              <el-button type="default"
                         size="mini"
                         icon="el-icon-tickets"
                         @click="updateHandler(row.id)">详情</el-button>
              <el-button v-if="row.status!=3&&row.status!=0&&authAttr>2"
                         type="danger"
                         size="mini"
                         icon="el-icon-delete"
                         @click="delHandler(row.id)">注销</el-button>

              <el-button @click="changeAddDialogVisible(row.id,row.seller)"
                         type="success"
                         size="mini"
                         icon="el-icon-edit"
                         v-if="row.status!=3&&row.status!=0&&authAttr>1">修改渠道</el-button>
            </el-button-group>

          </template>

        </el-table-column>

      </el-table>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <el-pagination background
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="queryParams.pageNum"
                         :page-sizes="[10, 20, 30, 50]"
                         :page-size="queryParams.pageSize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </el-row>
      </template>
    </el-card>

    <!-- 修改用户渠道对话框 -->
    <template>
      <el-dialog title="修改用户渠道"
                 :visible.sync="addDialogVisible"
                 width="35%">
        <div class="demo-input-suffix">
          <el-form :model="channelList"
                   ref="channelList"
                   :rules="rules"
                   label-width="80px">
            <!-- :model="form" -->
            <el-form-item label="用户id">
              <el-input v-model="channelList.userId"
                        disabled></el-input>
            </el-form-item>
            <el-form-item label="渠道名"
                          prop="sellerId">
              <template>
                <el-select v-model="channelList.sellerId"
                           placeholder="请输入渠道名">
                  <el-option v-for="item in sellerList"
                             :key="item.id"
                             :label="item.username"
                             :value="item.id">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="submitHandler('channelList')">确 定</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 详情 -->
    <template>
      <el-dialog title="详情信息"
                 :visible.sync="details"
                 width="35%">
        <div class="demo-input-suffix">
          <el-form ref="form"
                   label-width="80px">
            <!-- :model="form" -->
            <el-form-item label="ID">
              <el-input v-model="xqlist.id"
                        :disabled="true">
              </el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="xqlist.name"
                        :disabled="true">
              </el-input>
            </el-form-item>
            <el-form-item label="手机">
              <el-input v-model="xqlist.phone"
                        :disabled="true">
              </el-input>
            </el-form-item>
            <el-form-item label="头像">

              <img v-if="xqlist.avatar"
                   :src="$url+xqlist.avatar"
                   min-width="70"
                   height="70" />
            </el-form-item>
            <el-form-item label="性别">
              <el-input v-bind:value="sex(xqlist)"
                        :disabled="true">
              </el-input>
            </el-form-item>
            <el-form-item label="年龄">
              <el-input v-bind:value="xqlist.age"
                        :disabled="true">
              </el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-input v-bind:value="xqlist.status | filterStatus"
                        :disabled="true">
              </el-input>
            </el-form-item>
            <el-form-item label="渠道名">
              <el-input v-model="xqlist.sellerName"
                        :disabled="true">
              </el-input>
            </el-form-item>
            <el-form-item label="注册时间">
              <el-input v-model="xqlist.createdTime"
                        :disabled="true">
              </el-input>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button type="primary"
                     @click="details = false">确 定</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      channelList: {
        userId: '',
        sellerId: ''
      },
      sellerList: [],
      id: '',
      name: '',
      timeout: null,
      czlsList: [],
      xqlist: {},
      //获取用户列表传参
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        seller: ''
      },
      total: 0,
      // 控制修改密码
      addDialogVisible: false,
      // 控制注销用户
      alteruser: false,
      //获取用户详情
      details: false,
      // 修改用户信息传参
      recompose: {
        id: '',
        seller: ''
      },
      queryParams2: {
        pageNum: 1,
        pageSize: 100,
        type: 4
      },
      authAttr: '',
      rules: {
        sellerId: [{ required: true, message: '请选择渠道名', trigger: 'change' }]
      }
    }
  },
  mounted() {
    this.czls()
    this.getAuthAttr()
  },
  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.czls()
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-2')
      this.authAttr = authAttr
    },
    // 点击修改渠道名获取销售
    async changeAddDialogVisible(id, sellerId) {
      this.addDialogVisible = true
      this.channelList.userId = id
      this.channelList.sellerId = sellerId
      const { data: res } = await this.$http.get('/admin/admin/getAdminList', { params: this.queryParams2 })
      this.sellerList = res.data.list
    },
    async querySearchAsync(queryString, cb) {
      clearTimeout(this.timeout)
      var results = []
      if (queryString == '') {
        cb(results)
      } else {
        const { data: res } = await this.$http.get('/admin/admin/getAdminList', { params: this.queryParams })
        console.log(res)
        //掉接口需要的参数

        //这里去掉后端的接口.根据自己接口的情况进行赋值

        if (res.isFirstPage == 'true') {
          var result = res.data.list
          //循环放到一个远程搜索需要的数组
          for (let i = 0; i < result.length; i++) {
            const element = result[i]
            results.push({
              value: element.username,
              id: element.id
            })
          }
          cb(results)
        } else {
          results = []
          cb(results)
        }
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect(item) {
      this.id = item.id
      this.name = item.value
    },

    submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(`/admin/user/updateUserSeller?id=${this.channelList.userId}&seller=${this.channelList.sellerId}`)
          if (res.code !== 200) return this.$message.error(res.msg)
          this.czls()
          this.addDialogVisible = false
        }
      })
      // this.recompose.type = 1
      // console.log(this.recompose)
      // const { data: res } = await this.$http.post(`/admin/user/updateUserSeller?id=${this.userId}&seller=${this.sellerId}`)
      // console.log(res)
      // if (res.code !== 200) return this.$message.error(res.msg)
      // this.czls()
      // if (this.formData.id) {
      //   var { data: res } = await this.$http.put('/com/xqqType', this.recompose)
      // } else {
      //   var { data: res } = await this.$http.post('/com/xqqType', this.recompose)
      // }
      // if (res.code !== 200) return this.$message.error(res.msg)
      // //刷新页面
      // this.getXqTypeList()
      //关闭弹窗
      // this.addDialogVisible = false
    },
    // 获取用户列表
    async czls() {
      const { data: res } = await this.$http.get('/admin/user/getUserList', { params: this.queryParams })
      console.log(res)
      this.czlsList = res.data.list
      this.total = res.data.total
    },
    // 每页大小改变时
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.czls()
    },
    // 当前页码改变时触发
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.czls()
    },
    //
    sex(row) {
      if (row.gender == 0) {
        return '男'
      } else if (row.gender == 1) {
        return '女'
      } else {
        return '未知'
      }
    },
    open3() {
      this.$message({
        message: '功能未开发完善哦！',
        type: 'warning'
      })
    },
    // 修改状态
    async updateBrandStatus(row) {
      console.log(row)

      const { data: res } = await this.$http.post(`/admin/user/updateUserStatus?id=${row.id}&status=${row.status}`)
      this.czls()
    },
    async delHandler(id) {
      const msg = await this.$confirm('是否确认注销该用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.post(`/admin/user/cancelUser/?id=${id}`)
        if (res.code != 200) return this.$message.error(res.msg)
        //删除成功了，刷新页面
        this.czls()
      }
    },
    async updateHandler(id) {
      this.details = true
      const { data: res } = await this.$http.get(`/admin/user/getUserInfo?id=${id}`)
      console.log(res)
      this.xqlist = res.data
    }
  },
  filters: {
    filterStatus: function (status) {
      if (status == 0) {
        return '未注册'
      } else if (status == 1) {
        return '正常'
      } else if (status == 2) {
        return '已锁定'
      } else if (status == 3) {
        return '已注销'
      } else return ''
    }
  }
}
</script>
<style lang='scss' scoped>
</style>