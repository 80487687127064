<!-- 订单模块 -->
<template>
  <div class="dtgl">
    <!-- 头部搜索框 -->
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="108px">
      <el-form-item label="筛选条件">
        <el-select size="medium"
                   v-model="queryParams.type"
                   placeholder="请选择类型">
          <el-option label="所有订单"
                     value="0"></el-option>
          <el-option label="未付款"
                     value="1"></el-option>
          <el-option label="已付款"
                     value="2"></el-option>
          <el-option label="已退款"
                     value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input size="medium"
                  v-model="queryParams.billNo"
                  clearable
                  placeholder="请输入账单号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input size="medium"
                  v-model="queryParams.keyword"
                  clearable
                  placeholder="请输入用户名或手机号"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="tableData"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">

          <el-table-column prop="id"
                           align="center"
                           label="订单号"
                           width="120"></el-table-column>
          <el-table-column prop="productName"
                           align="center"
                           label="产品名称"
                           width="150"></el-table-column>
          <el-table-column prop="status"
                           align="center"
                           :formatter="zt"
                           label="订单状态"
                           width="180"></el-table-column>
          <el-table-column prop="billNo"
                           align="center"
                           label="账单号"
                           width="150"></el-table-column>
          <el-table-column prop="price"
                           align="center"
                           label="价格"></el-table-column>
          <el-table-column prop="userName"
                           align="center"
                           label="用户名"></el-table-column>
          <el-table-column prop="payType"
                           align="center"
                           :formatter="lx"
                           label="付款类型"></el-table-column>
          <el-table-column align="center"
                           v-slot="{row}"
                           label="是否失效">
            <span v-if="row.valid==1">已失效</span>
            <span v-if="row.valid==0">未失效</span>
          </el-table-column>
          <el-table-column label="操作"
                           width="250">
            <template v-slot="{row}">
              <el-button-group>
                <el-button type="default"
                           size="mini"
                           icon="el-icon-tickets"
                           @click="delHandler(row.id)">详情</el-button>
                <el-button v-if="row.valid==0&&authAttr>1"
                           type="danger"
                           size="mini"
                           icon="el-icon-delete"
                           @click="updateHandler(row.id)&&authAttr>1">失效</el-button>
                <el-button v-if="row.valid==0&&row.status==1&&authAttr>1"
                           type="warning"
                           size="mini"
                           icon="el-icon-s-release"
                           @click="drawback(row.id)">退款</el-button>
                <el-button v-if="row.valid==0&&row.status==0&&authAttr>1"
                           type="success"
                           size="mini"
                           @click="confirmOrder(row.id)">确认收款</el-button>

              </el-button-group>

            </template>

          </el-table-column>
        </el-table>
      </template>

      <!-- 详情 -->
      <template>
        <el-dialog title="详情信息"
                   :visible.sync="details"
                   width="35%">
          <div class="demo-input-suffix">
            <el-form ref="form"
                     label-width="80px">
              <!-- :model="form" -->
              <el-form-item label="订单号">
                <el-input v-model="xqlist.id"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="产品名称">
                <el-input v-model="xqlist.productName"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="订单状态">
                <el-input v-bind:value="zt(xqlist)"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="账单号">
                <el-input v-model="xqlist.billNo"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="价格">
                <el-input v-model="xqlist.price"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="付款类型">
                <el-input v-bind:value="lx(xqlist)"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="付款时间">
                <el-input v-model="xqlist.payTime"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="用户名">
                <el-input v-model="xqlist.userName"
                          :disabled="true">
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer"
                class="dialog-footer">
            <el-button type="primary"
                       @click="details = false">确 定</el-button>
          </span>
        </el-dialog>
      </template>

      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <el-pagination background
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="queryParams.pageNum"
                         :page-sizes="[10, 20, 30, 50]"
                         :page-size="queryParams.pageSize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </el-row>
      </template>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: '0',
        billNo: '',
        keyword: ''
      },
      tableData: [],
      total: 0,
      addDialogVisible: false,
      details: false,
      xqlist: {},
      authAttr: ''
    }
  },
  mounted() {
    this.getXqTypeList()
    this.getAuthAttr()
  },

  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getXqTypeList()
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-3')
      this.authAttr = authAttr
    },
    // 查询表格数据
    async getXqTypeList() {
      const { data: res } = await this.$http.get('/admin/order/getOrderList', { params: this.queryParams })
      console.log(res)
      if (res.code != 200) return this.$msgErr(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    // 每页大小改变时
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.getXqTypeList()
    },
    // 当前页码改变时触发
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getXqTypeList()
    },
    //判断身份认证状态
    lx(row) {
      if (row.payType == 1) {
        return '支付宝'
      } else if (row.payType == 2) {
        return '微信'
      } else if (row.payType == 3) {
        return '银行卡'
      } else {
        return '现金'
      }
    },
    zt(row) {
      if (row.status == 0) {
        return '未付款'
      } else if (row.status == 1) {
        return '已付款'
      } else {
        return '已退款'
      }
    },
    //退款
    async drawback(id) {
      const msg = await this.$confirm('你确定需要退款?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.post(`/admin/order/backOrder?id=${id}`)
        if ((res.code = 200)) {
          this.getXqTypeList()
          return this.$message.success('退款成功')
        }
      }
    },
    async confirmOrder(id) {
      var e = await this.$prompt('您正在确认账单,请填写账单号', '提示', {
        inputPlaceholder: '请输入账单号',
        confirmButtonText: '确定提交',
        cancelButtonText: '取消',
        closeOnClickModal: true,
        type: 'warning'
      })
      if (e.action == 'confirm' && e.value != null && e.value != '' && e.value.length >= 10 && e.value.length <= 100) {
        const { data: res } = await this.$http.post(`/admin/order/confirmOrder?id=${id}&payNo=${e.value}&flag=1`)
        if ((res.code = 200)) {
          this.getXqTypeList()
          return this.$message.success('确认成功')
        }
      } else {
        this.$message.error('输入错误')
      }
    },
    async updateHandler(id) {
      const msg = await this.$confirm('你确定要失效该订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.post(`/admin/order/delOrder?id=${id}`)
        if (res.code == 200) {
          this.$message.success('删除成功')
          this.getXqTypeList()
        }
      }
    },
    // 详情
    async delHandler(id) {
      this.details = true
      const { data: res } = await this.$http.get(`/admin/order/getOrderInfoById?id=${id}`)
      console.log(res)
      this.xqlist = res.data
    }
  }
}
</script>
<style lang='scss' scoped>
.dtgl {
  font-size: 14px;
  overflow-x: hidden;
  padding: 0 18px;
  .search-bar {
    padding: 14px 0px;
    min-width: 990px;
  }

  .btns-curd {
    padding: 14px 0px;
  }

  .pagination {
    padding-top: 10px;
    text-align: right;
  }
}
</style>