<!-- 首页 -->
<template>
  <div id="app"
       @click="tagMenuShow=false">
    <el-container class="container">
      <!-- 左 -->
      <!-- 左侧图标 -->
      <el-aside width="200px"
                class="aside">
        <div class="logo">
          <img src="../assets/images/logo.png"
               alt="">
          <h1>高美销售后台</h1>
        </div>
        <!-- 左侧菜单栏 -->
        <el-menu :default-active="currentPath"
                 class="menu-left"
                 background-color="#304156"
                 text-color="#fff"
                 active-text-color="#ffd04b"
                 :unique-opened="true"
                 router>
          <template v-for="item in menuList">
            <el-submenu :index="item.path"
                        :key="item.path"
                        v-if="item.children&&item.visiable">
              <!-- 一级菜单里面包含二级菜单 -->
              <template slot="title">
                <span>{{item.meta.title}}</span>
              </template>
              <!-- 二级菜单 -->
              <template v-for="menu in item.children">
                <el-menu-item class="second"
                              :index="menu.path"
                              :key="menu.path"
                              v-if="!menu.hidden&&menu.visiable">
                  <i :class="'iconfont icon-'+menu.meta.icon"></i>
                  {{menu.meta.title}}
                </el-menu-item>
              </template>
            </el-submenu>
            <!-- 一级菜单 -->
            <el-menu-item :index="item.path"
                          :key="item.path"
                          v-show="item.visiable"
                          v-if="!item.children&&item.visiable">
              <i :class="'iconfont icon-'+item.meta.icon"></i>
              <span slot="title">{{item.meta.title}}</span>
            </el-menu-item>
          </template>
        </el-menu>

      </el-aside>
      <!-- 右 -->
      <el-main>
        <!-- 顶部导航 -->
        <el-row type="flex"
                justify="space-between"
                align="middle">
          <!-- 折叠 -->
          <el-col :span="1"
                  class="hidn-show">
            <span class="iconfont icon-shousuo"></span>
          </el-col>
          <!-- 导航栏 -->
          <el-col :span="20">
            <el-tabs v-model="activeName">
              <!-- @tab-click="handleClick" -->
              <el-tab-pane v-for="(item) in routerList"
                           :key="item.path">
                <span slot="label"><i :class="'iconfont icon-'+item.meta.icon"></i>{{item.meta.title}}</span>
              </el-tab-pane>
            </el-tabs>
          </el-col>
          <!-- 头像 -->
          <el-col :span="2"><span>{{this.txname}}</span></el-col>
          <el-col :span="2">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <img class="avatar"
                     :src="$url+imglist" />
                <i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="clearfix"
                                  @click.native="tc">
                  退出登录
                  <el-badge class="mark" />
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
        <!-- 顶部标签页 -->
        <div class="tagbar"
             @mousewheel="tagScroll">
          <el-scrollbar :vertical="false"
                        ref="scroll">
            <div class="list"
                 style="width:auto; white-space:nowrap;">
              <template v-for="(item,index) in tagList">
                <router-link :key="item.path"
                             :to="item.path">
                  <!-- 这里会出现点击这里还会触发roter-link这个事件所以我们使用prevent（总指向它）这个 -->
                  <el-tag @contextmenu.prevent.native="contextmenuHandler($event,item,index)"
                          @close.prevent="closeHandler(item,index)"
                          effect="plain"
                          class="item"
                          :class="{active: item.status}"
                          size="small"
                          :closable="item.title !='首页'">{{item.title}}</el-tag>
                </router-link>
              </template>
            </div>
          </el-scrollbar>
          <!-- tag操作列表 -->
          <ul class="contextmenu"
              v-show="tagMenuShow"
              :style="{left:x, top:y}">
            <li @click="refreshPage">刷新页面</li>
            <li>关闭当前</li>
            <li>关闭其他</li>
            <li>关闭右侧</li>
            <li>关闭所有</li>
          </ul>
        </div>
        <!-- 展示区域 -->
        <div class="zs">
          <transition name="fade"
                      mode="out-in">
            <router-view :key="time"></router-view>
          </transition>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import user from '../views/management-center/user/user.vue'
import info from '../views/management-center/info/info.vue'
import personnel from '../views/management-center/admin/personnel.vue'
import role from '../views/management-center/admin/role.vue'
import auth from '../views/management-center/admin/auth.vue'
import article from '../views/management-center/article/article.vue'
import order from '../views/management-center/order/order.vue'
import product from '../views/management-center/product/product.vue'
import seller from '../views/management-center/seller/seller.vue'
import property from '../views/management-center/recommend/property.vue'
import record from '../views/management-center/recommend/record.vue'
import userOrder from '../views/management-center/userOrder/userOrder.vue'
export default {
  data() {
    return {
      //管理员信息
      adminxx: {
        type: '1'
      },
      //用户名字
      txname: '',
      //用户头像
      imglist: '',
      // 个人信息
      userInfo: [],
      // 全部数据
      routerList: [],
      // 左侧菜单栏
      menuList: [
        {
          name: '/index',
          path: '/index',
          hidden: false,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER', 'ROLE_SELLER'],
          component: 'index',
          visiable: false,
          meta: {
            title: '首页',
            icon: 'build',
            noCache: false
          }
        },

        {
          name: '/admin',
          path: '/admin',
          hidden: false,
          redirect: 'noRedirect',
          component: 'Layout',
          alwaysShow: true,
          visiable: false,
          authType: 5,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          meta: {
            title: '管理员模块',
            icon: 'build',
            noCache: false
          },
          children: [
            {
              name: '/admin/personnel',
              path: '/admin/personnel',
              hidden: false,
              component: personnel,
              meta: {
                title: '人员管理',
                noCache: false
              },
              visiable: false,
              roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER']
            },
            {
              name: '/admin/role',
              path: '/admin/role',
              hidden: false,
              component: role,
              meta: {
                title: '角色管理',
                icon: 'people',
                noCache: false
              },
              roles: ['ROLE_ROOT'],
              visiable: false
            },
            {
              name: '/admin/auth',
              path: '/admin/auth',
              hidden: false,
              component: auth,
              meta: {
                title: '权限管理',
                icon: 'education',
                noCache: false
              },
              roles: ['ROLE_ROOT'],
              visiable: false
            }
          ]
        },
        {
          name: '/user',
          path: '/user',
          hidden: false,
          authType: 2,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: user,
          visiable: false,
          meta: {
            title: '用户管理',
            icon: 'build',
            noCache: false
          }
        },
        {
          name: '/article',
          path: '/article',
          visiable: false,
          authType: 1,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          hidden: false,
          component: article,
          meta: {
            title: '文章管理',
            icon: 'cascader',
            noCache: false
          }
        },
        {
          name: '/product',
          path: '/product',
          visiable: false,
          hidden: false,
          authType: 4,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: product,
          meta: {
            title: '产品管理',
            icon: 'build',
            noCache: false
          }
        },
        {
          name: '/order',
          visiable: false,
          path: '/order',
          hidden: false,
          authType: 3,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: order,
          meta: {
            title: '订单管理',
            icon: 'checkbox',
            noCache: false
          }
        },
        {
          name: '/seller',
          path: '/seller',
          roles: ['ROLE_SELLER'],
          hidden: false,
          component: seller,
          meta: {
            title: '我的用户',
            icon: 'color',
            noCache: false
          }
        },
        {
          name: '/userOrder',
          path: '/userOrder',
          roles: ['ROLE_SELLER'],
          hidden: false,
          component: userOrder,
          meta: {
            title: '用户下单',
            icon: 'color',
            noCache: false
          }
        },
        {
          name: '/recommend',
          path: '/recommend',
          hidden: false,
          redirect: 'noRedirect',
          component: 'recommend',
          alwaysShow: true,
          visiable: false,
          authType: 11,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          meta: {
            title: '推荐模块',
            icon: 'build',
            noCache: false
          },
          children: [
            {
              name: '/recommend/property',
              path: '/recommend/property',
              hidden: false,
              component: property,
              meta: {
                title: '资产管理',
                noCache: false
              },
              visiable: false,
              roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER']
            },
            {
              name: '/recommend/record',
              path: '/recommend/record',
              hidden: false,
              component: record,
              meta: {
                title: '推荐记录管理',
                icon: 'people',
                noCache: false
              },
              roles: ['ROLE_ROOT'],
              visiable: false
            }
          ]
        },
        {
          name: '/info',
          path: '/info',
          hidden: false,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER', 'ROLE_SELLER'],
          component: info,
          visiable: false,
          meta: {
            title: '个人信息',
            icon: 'build',
            noCache: false
          }
        }
      ],
      // 当前菜单全部数据的下标
      menuActiveIndex: 0,
      activeName: 'second',
      tagList: [{ title: '首页', path: '/index', status: true }],
      currentPath: '/',
      tagMenuShow: false,
      x: 0,
      y: 0,
      time: Date.now()
    }
  },
  mounted() {
    //如果currentTagList有缓存，则初始化tagList
    const currentTagList = sessionStorage.getItem('currentTagList')
    if (currentTagList) this.tagList = JSON.parse(currentTagList)
    //初始化当前的路径currentPath
    this.currentPath = this.$route.path
    this.tx()
    // this.getRouters(),
    this.setRoleShow()
    this.parseAuth()
  },

  watch: {
    // 监听路由的改变
    $route(route) {
      //更新currentPath当前路径
      this.currentPath = route.path
      //先把所有状态改变false
      this.tagList.forEach((el) => (el.status = false))
      //当前路由
      const { path, meta } = route
      //判断当前路由路径是否存在tagList数组中
      let tag = this.tagList.find((el) => el.path == path)
      if (tag) {
        tag.status = true
      } else {
        //如果不存在则添加到tagList数组中
        tag = { title: meta.title, path: path, status: true }
        this.tagList.push(tag)
      }
      //把当前的路径存放到本地中
      if (tag.path === '/') {
        sessionStorage.setItem('currentTagList', JSON.stringify([tag]))
      }
      if (tag.path !== '/') {
        sessionStorage.setItem('currentTagList', JSON.stringify([{ title: '首页', path: '/index', status: true }, tag]))
      }
    }
  },
  methods: {
    parseAuth() {
      var roles = window.sessionStorage.getItem('role')
      if (roles == 'ROLE_ROOT') {
        for (var i = 1; i <= 11; i++) {
          window.sessionStorage.setItem('auth-' + i, 3)
        }
        return
      }
      var authList = window.sessionStorage.getItem('auth')
      var auths = JSON.parse(authList)
      var authL = []
      auths.forEach((auth) => {
        authL.push(auth.type)
        window.sessionStorage.setItem('auth-' + auth.type, auth.attribute)
      })
      this.menuList.forEach((element) => {
        if (element.authType == undefined) {
          return
        }
        if (!authL.includes(element.authType)) {
          element.visiable = false
        }
      })
    },
    setRoleShow() {
      var roles = window.sessionStorage.getItem('role')
      this.menuList.forEach((element) => {
        if (element.roles.includes(roles)) {
          element.visiable = true
        }
        if (element.children != undefined && element.children.length > 0) {
          element.children.forEach((item) => {
            if (item.roles.includes(roles)) {
              item.visiable = true
            } else {
              item.visiable = false
            }
          })
        }
      })
    },
    tc() {
      window.sessionStorage.removeItem('role')
      window.sessionStorage.removeItem('token')
      window.sessionStorage.removeItem('currentTagList')
      for (var i = 1; i <= 11; i++) {
        window.sessionStorage.removeItem('auth-' + i)
      }
      window.sessionStorage.removeItem('auth')
      window.sessionStorage.removeItem('tokenStartTime')
      this.$router.push('/')
    },
    //头像信息
    async tx() {
      const { data: res } = await this.$http.get('/admin/admin/getAdminInfo')
      console.log(res)
      this.txname = res.data.username
      this.imglist = res.data.avatar
    },
    //点击刷新处理
    refreshPage() {
      const { tag, tagIndex } = this
      //判断刷新是否是当前的选择项
      if (tag && tag.status) {
        this.time = Date.now()
      }
      if (tag && tag.status == false) {
        this.$router.push(tag.path)
      }
    },
    //处理右击显示tag操作菜单
    contextmenuHandler(event, item, index) {
      this.tagMenuShow = true
      this.x = event.clientX + 10 + 'px'
      this.y = event.clientY + 10 + 'px'
    },
    //处理tag的滚动
    tagScroll(event) {
      const wheelDelta = event.wheelDelta
      const wrap = this.$refs.scroll.wrap
      //在当前滚动条的位置基础上做移动
      wrap.scrollLeft = wrap.scrollLeft + wheelDelta / 4
    },
    // 关闭tag处理
    closeHandler(tag, index) {
      //判断关闭是否是当前选中的
      if (tag.status) {
        let t = null
        //判断当前选中的是否为最后一个tag
        if (index == this.tagList.length - 1) {
          t = this.tagList[index - 1] //获取当一个tag对象
        } else {
          t = this.tagList[this.tagList.length - 1] //获取最后一个tag对象
        }
        //改变状态
        t.status = true
        this.$router.push(t.path)
      }
      this.tagList = this.tagList.filter((el, i) => i != index)
    }
    // 个人信息
    // async getInfo() {
    //   const { data: res } = await this.$http.get('/getInfo')
    //   if (res.code != 200) return this.$message.error(res.msg)
    //   this.userInfo = res.user
    //   // console.log(res)
    // },
    // // 请求页面路由信息
    // async getRouters() {
    //   const { data: res } = await this.$http.get('/getRouters')
    //   if (res.code != 200) return this.$message.error(res.msg)
    //   this.routerList = res.data

    //   this.menuList = this.routerList[this.menuActiveIndex].children
    // },
    // handleClick(name) {
    //   this.menuActiveIndex = name.index
    //   this.getRouters()
    // }
  }
}
</script>
<style lang='scss' scoped>
// 设置切换动画
.fade-level-active,
.fade-enter-active {
  transition: all 0.5s;
}
.avatar {
  width: 30px;
  height: 30px;
}
.fade-enter {
  opacity: 0;
  transform: translateX(-30px);
}
.fade-level-to {
  opacity: 0;
  transform: translateX(30px);
}

.container {
  width: 100vw;
  height: 100vh;
}
.aside {
  background: #304156;
}
// 左边logo
.logo {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  img {
    width: 32px;
    width: 32px;
    margin-right: 10px;
  }
}
.menu-left {
  border-right: none;
  .second {
    background: #1f2d3d !important;
    min-width: 0;
  }
  .second:hover {
    background: #001528 !important;
  }
}
// 右边折叠
.hidn-show {
  span {
    width: 100%;
    font-size: 20px;
  }
}
// 头像
.el-dropdown-link {
  margin-left: 25px;
}
// 顶部可关闭标签页
.tagbar {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 /12%), 0 0 3px 0 rgb(0 0 0 /4%);

  .list {
    padding-left: 14px;
    height: 34px;
    line-height: 34px;
    .item {
      margin-right: 5px;
    }
    .active {
      background: #1890ff;
      color: #fff;

      &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #fff;
        border-radius: 50%;
      }
    }
  }
}
// 展示区域
.zs {
  margin-top: 10px;
}
//tag操作列表
.contextmenu {
  position: absolute;
  background: #fff;
  width: 88px;
  box-shadow: 2px 2px 3px 0 rgb(0 0 0 / 30%);
  font-size: 12px;
  color: #999;
  text-align: center;
  z-index: 999;
  li {
    padding: 10px;

    &:hover {
      background: #eeeeee;
    }
  }
}
</style>