<!--首页 -->
<template>
  <div>
    欢迎登录后台管理系统！！！
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>
<style lang='scss' scoped>
</style>