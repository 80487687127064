import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// 浏览器顶部进度条
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
NProgress.configure({
	easing: 'ease', // 动画方式
	speed: 500, // 递增进度条的速度
	showSpinner: false, // 是否显示加载ico
	trickleSpeed: 200, // 自动递增间隔
	minimum: 0.3 // 初始化时的最小百分比
});
//当路由进入前
router.beforeEach((to, from, next) => {
	// 每次切换页面时，调用进度条
	NProgress.start();
	// 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
	next();
});
//当路由进入后：关闭进度条
router.afterEach(() => {
	// 在即将进入新的页面组件前，关闭掉进度条
	NProgress.done();
});
// // 引入图标
// import '@/assets/font/iconfont.css';
// 引入element
import './plugins/element.js';
// 引入axios
import axios from 'axios';
axios.defaults.baseURL = '/api';

// 请求拦截器
axios.interceptors.request.use(
	(config) => {
		// if (token) config.headers.authorization = token;
		config.headers['token'] = sessionStorage.getItem('token') || '';
		return config;
	},
	(error) => Promise.reject(error)
);
// 添加响应拦截器
axios.interceptors.response.use(
	function (response) {
		// 对响应数据做点什么
		return response;
	},
	(error) => Promise.reject(error)
);
Vue.prototype.$http = axios;
Vue.prototype.$url = 'http://sale.gomicsgene2.com:8085';
// 初始化样式
import './assets/css/cssreset.css';

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
