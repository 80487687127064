import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import login from '../views/login.vue';
import Home from '../views/Home.vue';
import Welcome from '../views/Welcome.vue';
// 用户管理信息
import user from '../views/management-center/user/user.vue';
import info from '../views/management-center/info/info.vue';
import personnel from '../views/management-center/admin/personnel.vue';
import role from '../views/management-center/admin/role.vue';
import auth from '../views/management-center/admin/auth.vue';
import article from '../views/management-center/article/article.vue';
import order from '../views/management-center/order/order.vue';
import product from '../views/management-center/product/product.vue';
import seller from '../views/management-center/seller/seller.vue';
import record from '../views/management-center/recommend/record.vue';
import property from '../views/management-center/recommend/property.vue';
import userOrder from '../views/management-center/userOrder/userOrder.vue';

const router = new VueRouter({
	routes: [
		{
			path: '/',
			redirect: (to) => {
				var tokenStr = window.sessionStorage.getItem('token');
				if (tokenStr == null) {
					return '/login';
				} else {
					return '/index';
				}
			}
		},
		{
			path: '/login',
			component: login,
			name: 'login',
			meta: { title: '登录' }
		},
		{
			path: '/',
			component: Home,
			name: 'Home',
			meta: { title: '首页' },
			children: [
				{ path: '/index', component: Welcome, name: 'Welcome', meta: { title: '首页' }, alias: '/index' },
				{ path: '/admin/role', component: role, name: role, meta: { title: '角色管理' } },
				{ path: '/admin/personnel', component: personnel, name: personnel, meta: { title: '人员管理' } },
				{ path: '/admin/auth', component: auth, name: auth, meta: { title: '权限管理' } },
				{ path: '/user', component: user, name: user, meta: { title: '用户管理' } },
				{ path: '/article', component: article, name: article, meta: { title: '文章管理' } },
				{ path: '/product', component: product, name: product, meta: { title: '产品管理' } },
				{ path: '/order', component: order, name: order, meta: { title: '订单管理' } },
				{ path: '/info', component: info, name: info, meta: { title: '个人信息' } },
				{ path: '/seller', component: seller, name: seller, meta: { title: '我的用户' } },
				{ path: '/recommend/record', component: record, name: record, meta: { title: '推荐记录管理' } },
				{ path: '/recommend/property', component: property, name: property, meta: { title: '资产管理' } },
				{ path: '/userOrder', component: userOrder, name: userOrder, meta: { title: '用户下单' } }
			]
		},
		{ path: '*', redirect: '/index' }
	]
});

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
	// to 将要访问的路径
	// from 代表从哪个路径跳转而来
	// next 是一个函数， 表示放行   ①next()放行   ②next('/login')强制跳转
	var token = window.sessionStorage.getItem('token');
	if (to.path === '/login') {
		if (token != null) {
			return next('/');
		}
		return next();
	} // 访问登录页，直接放行
	// 获取token

	if (!token) return next('/login'); // 没有token 强制跳转

	// 获取存储token的开始时间
	const tokenStartTime = window.sessionStorage.getItem('tokenStartTime');
	console.log('AE86' + tokenStartTime);
	// 后台给出的token有效时间，一个星期 单位 是秒
	// 我们自己定义6个小时过期，让用户重新登录一下， 用户总不可能在一个页面挂机一天吧
	const timeOver = 21600000;
	console.log(timeOver);
	// 当前时间
	let date = new Date().getTime();
	// 如果大于说明是token过期了
	if (date - tokenStartTime > timeOver) {
		sessionStorage.removeItem('token');
		token = null;
	}
	// 如果token过期了
	if (!token) {
		if (to.path == '/login') return next();
		// 注意要import element的Message组件
		alert('登录状态过期，请重新登录');
		return next('/login');
		// 如果token没有过期，又是选择了登录页面就直接重定向到首页，不需要重新输入账户密码
	} else if (to.path == '/login') {
		return next('');
	}
	next(); // 否则直接放行
});

export default router;
