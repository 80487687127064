<!--管理员信息  -->
<template>
  <div class="czls">
    <el-card class="box-card">
      <div slot="header"
           class="clearfix">
        <span>个人信息</span>
      </div>
      <div class="text item">用户名:{{ userInfo.username }}</div>
      <div class="text item">手机号:{{ userInfo.phone }}</div>
      <div class="text item">
        头像:<br />
        <img v-if="userInfo.avatar"
             class="img"
             :src="$url+userInfo.avatar"
             width="100px"
             height="100px"
             alt="头像" />
      </div>
      <template>
        <el-row class="btns-curd"
                :gutter="10"
                align="middle"
                type="flex">
          <el-col :span="1.5">
            <el-button @click="addDialogVisible = true"
                       type="primary"
                       plain
                       size="mini"
                       icon="el-icon-s-tools">修改密码</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button @click="updAdminInfo"
                       type="success"
                       plain
                       size="mini"
                       icon="el-icon-user">修改信息</el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button type="danger"
                       @click="altervia = true"
                       plain
                       size="mini"
                       icon="el-icon-upload">修改头像</el-button>
          </el-col>
        </el-row>
      </template>
    </el-card>
    <!-- 修改密码对话框 -->
    <template>
      <el-dialog title="修改密码"
                 :visible.sync="addDialogVisible"
                 width="35%">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules"
                   label-width="80px">
            <!-- :model="form" -->
            <el-form-item label="原密码"
                          prop="oldPassword">
              <el-input v-model="recompose.oldPassword"
                        placeholder="请输入原密码"
                        show-password>></el-input>
            </el-form-item>
            <el-form-item label="新密码"
                          prop="newPassword">
              <el-input v-model="recompose.newPassword"
                        placeholder="请输入新密码"
                        show-password>></el-input>
            </el-form-item>
            <el-form-item label="新密码"
                          prop="newPassword2">
              <el-input v-model="recompose.newPassword2"
                        placeholder="请再次输入新密码"
                        show-password>></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="submitHandler('recompose')">确 定</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- 修改用户名对话框 -->
    <template>
      <el-dialog title="修改信息"
                 :visible.sync="alteruser"
                 width="35%">
        <div class="demo-input-suffix">

          <el-form :model="userdata"
                   ref="userdata"
                   :rules="rules"
                   label-width="80px">
            <!-- :model="form" -->
            <el-form-item label="用户名"
                          prop="newUsername">
              <el-input v-model="userdata.newUsername"
                        placeholder="请输入新用户名"></el-input>
            </el-form-item>
            <el-form-item label="手机号"
                          prop="phone">
              <el-input v-model="userdata.phone"
                        placeholder="请输入新手机号"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span style="color:coral;margin-left: 15%;">请至少选择一项修改</span></br>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="alteruser = false">取 消</el-button>
          <el-button type="primary"
                     @click="submituser('userdata')">提 交</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- 修改头像对话框 -->
    <template>
      <el-dialog title="修改头像"
                 :visible.sync="altervia"
                 width="35%">
        <el-form ref="form"
                 label-width="80px">
          <el-form-item label="头像">
            <el-upload action="/api/admin/admin/modifyAdminInfo"
                       name="avatar"
                       ref="upload"
                       :before-upload="beforeAvatarUpload"
                       :headers="headers"
                       :limit="1"
                       list-type="picture-card"
                       :data="queryParams"
                       :auto-upload="false">
              <i slot="default"
                 class="el-icon-plus"></i>
              <div slot="file"
                   slot-scope="{ file }">
                <img class="el-upload-list__item-thumbnail"
                     :src="file.url"
                     alt="" />
              </div>
            </el-upload> <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span><br>
          </el-form-item>
        </el-form>

        <span slot="footer"
              class="dialog-footer">
          <el-button @click="qxUpload()">取 消</el-button>
          <el-button type="primary"
                     @click="submitUpdateAvatar">确 定</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //查询管理员传参
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: 0
      },
      total: 0,
      // 控制修改密码
      addDialogVisible: false,
      // 控制修改用户名
      alteruser: false,
      altervia: false,
      // 修改用户信息传参
      userdata: {
        newUsername: '',
        phone: '',
        type: ''
      },
      recompose: {
        type: '',
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
        newUsername: '',
        avatar: '',
        phone: ''
      },
      // 新增信息
      xzdata: false,
      // 新增传参
      xzsj: {
        username: '',
        password: '',
        identity: ''
      },
      userInfo: {
        // username:"张三"
      },
      imageUrl: '',
      headers: {
        token: sessionStorage.getItem('token')
      },
      rules: {
        newUsername: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        phone: [{ min: 11, max: 11, message: '请输入11位的手机号', trigger: 'blur' }],
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        newPassword2: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.getAdminInfo()
  },
  methods: {
    updAdminInfo() {
      this.alteruser = true
      console.log(this.userInfo)
      this.userdata.phone = this.userInfo.phone
      this.userdata.newUsername = this.userInfo.username
    },
    qxUpload() {
      this.altervia = false
      this.$refs.upload.clearFiles()
    },
    submitUpdateAvatar() {
      this.queryParams.type = 3
      this.$refs.upload.submit()
      this.$refs.upload.clearFiles()
      this.altervia = false
      this.getAdminInfo()
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    async getAdminInfo() {
      this.recompose.type = 1
      console.log(this.recompose)
      const { data: res } = await this.$http.get(`/admin/admin/getAdminInfo`)
      this.userInfo = res.data
    },
    submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.recompose.type = 1
          if (this.recompose.newPassword != this.recompose.newPassword2) {
            this.$message.error('两次新密码不同')
            return
          }
          if (this.recompose.newPassword == '') {
            this.$message.error('新密码不为空')
            return
          }
          if (this.recompose.oldPassword == '') {
            this.$message.error('原密码不为空')
            return
          }
          console.log(this.recompose)
          const { data: res } = await this.$http.post(`/admin/admin/modifyAdminInfo?type=${this.recompose.type}&oldPassword=${this.recompose.oldPassword}&newPassword=${this.recompose.newPassword}`)
          console.log(res)
          if (res.code !== 200) return this.$message.error(res.msg)
          if (res.code == 200) {
            alert('密码修改成功，请重新登录')
            window.sessionStorage.removeItem('role')
            window.sessionStorage.removeItem('token')
            window.sessionStorage.removeItem('currentTagList')
            for (var i = 1; i <= 5; i++) {
              window.sessionStorage.removeItem('auth-' + i)
            }
            window.sessionStorage.removeItem('auth')
            window.sessionStorage.removeItem('tokenStartTime')
            this.$router.push('/')
          }
          this.addDialogVisible = false
        }
      })
    },
    // 修改用户名
    submituser(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.userdata.type = 2
          const { data: res } = await this.$http.post(
            `/admin/admin/modifyAdminInfo?type=${this.userdata.type}&newUsername=${this.userdata.newUsername}&phone=${this.userdata.phone == null ? '' : this.userdata.phone}`
          )
          console.log(res)
          this.alteruser = false
          this.userdata.newUsername = ''
          this.getAdminInfo()
          if (res.code !== 200) return this.$message.error(res.msg)
          if (res.code == 200) return this.$message.success('修改成功')
        }
      })

      //关闭弹窗
    },
    open4() {
      this.xzdata = true
    },
    // 修改状态
    async updateBrandStatus(row) {
      console.log(row)
      const { data: res } = await this.$http.post(`/admin/admin/setAdminStatus?id=${row.id}&status=${row.status}`)
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  margin-left: 10%;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
  margin-right: 40px;
  float: left;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
