<template>
  <div class="xqq">
    <el-card class="box-card">
      <el-form label-width="80px"
               ref="myform">

        <el-form-item label="手机号或姓名"
                      prop="keyword"
                      label-width="100px">
          <el-input v-model="queryParams.keyword"
                    placeholder="请输入手机号或姓名"
                    clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     icon="el-icon-search"
                     size="medium"
                     @click="getXqTypeList">搜索</el-button>
        </el-form-item>
      </el-form>
      <!-- 数据表格 -->
      <template>
        <el-table align="center"
                  :data="tableData"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="name"
                           align="center"
                           label="姓名"
                           width="180"></el-table-column>
          <el-table-column prop="phone"
                           align="center"
                           label="手机"
                           width="180"></el-table-column>
          <el-table-column prop="avatar"
                           align="center"
                           label="头像"
                           width="180">
            <template slot-scope="scope">
              <img v-if="scope.row.avatar"
                   :src="$url+scope.row.avatar"
                   min-width="70"
                   height="70" />
            </template>
          </el-table-column>
          <el-table-column prop="gender"
                           align="center"
                           width="180"
                           label="性别"
                           :formatter="sex"></el-table-column>
          <el-table-column prop="isCancel"
                           align="center"
                           width="180"
                           label="状态"
                           :formatter="ic"></el-table-column>
          <el-table-column align="center"
                           label="操作"></el-table-column>
        </el-table>
      </template>

      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <el-pagination background
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="queryParams.pageNum"
                         :page-sizes="[10, 20, 30, 50]"
                         :page-size="queryParams.pageSize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </el-row>
      </template>
    </el-card>

  </div>
</template>

<script>
export default {
  mounted() {
    this.getXqTypeList()
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      tableData: [],
      total: 0,
      editDisabled: true,
      delDisabled: true
    }
  },
  methods: {
    // 查询表格数据
    async getXqTypeList() {
      const { data: res } = await this.$http.get('/admin/admin/getUserListBySeller', { params: this.queryParams })
      console.log(res)
      if (res.code != 200) return this.$message.error(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    sex(row) {
      if (row.gender == 0) {
        return '男'
      } else {
        return '女'
      }
    },
    ic(row) {
      if (row.isCancel == 0) {
        return '正常'
      } else {
        return '已注销'
      }
    },
    // 每页大小改变时
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.getXqTypeList()
    },
    // 当前页码改变时触发
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getXqTypeList()
    }
  }
}
</script>

<style lang="scss" scoped>
.xqq {
  font-size: 14px;
  overflow-x: hidden;
  padding: 0 18px;
  .search-bar {
    padding: 14px 0px;
    min-width: 990px;
  }

  .btns-curd {
    padding: 14px 0px;
  }

  .pagination {
    padding-top: 10px;
    text-align: right;
  }
}
</style>