<!--管理员信息  -->
<template>
  <div class="czls">
    <!-- 顶部搜索 -->
    <el-form label-width="80px"
             v-if="authAttr>1"
             ref="myform"
             size="small"
             :inline="true">
      <el-form-item v-if="authAttr>2"
                    label="查询管理员"
                    label-width="100px"
                    prop="zhzt">
        <el-select v-model="queryParams.type"
                   placeholder="请选择"
                   clearable>
          <el-option label="查所有人员"
                     value="0"></el-option>
          <el-option v-if="authAttr>2"
                     label="查产品管理员"
                     value="2"></el-option>
          <el-option v-if="authAttr>2"
                     label="查销售管理员"
                     value="3"></el-option>
          <el-option v-if="authAttr>2"
                     label="查所有销售"
                     value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button v-if="authAttr>2"
                   type="primary"
                   icon="el-icon-search"
                   size="medium"
                   @click="czls">搜索</el-button>
        <el-button v-if="authAttr>1"
                   @click="open4"
                   type="success"
                   plain
                   size="medium"
                   icon="el-icon-plus">新增管理员</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- (CURD)操作按钮 -->
      <template>
        <el-row class="btns-curd"
                :gutter="10"
                align="middle"
                type="flex">

        </el-row>
      </template>
      <!-- 表格区域 -->
      <el-table :data="czlsList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">

        <el-table-column prop="id"
                         align="center"
                         label="管理员编号"
                         width="150">

        </el-table-column>

        <el-table-column prop="username"
                         align="center"
                         label="用户名"
                         width="150">
        </el-table-column>
        <el-table-column prop="status"
                         align="center"
                         label="状态"
                         width="200">
          <template slot-scope="scope">
            <span v-if="authAttr<=1">
              <span v-if="scope.row.status==0">已启用</span>
              <span v-if="scope.row.status==1">已禁用</span>
            </span>
            <el-switch v-if="authAttr>1"
                       v-model="scope.row.status"
                       active-color="#13ce66"
                       inactive-color="#ff4949"
                       :active-value="0"
                       :inactive-value="1"
                       active-text="启用"
                       inactive-text="禁用"
                       @change="updateBrandStatus(scope.row)"></el-switch>
          </template>

        </el-table-column>

        <el-table-column prop="avatar"
                         align="center"
                         label="头像"
                         width="200">
          <template slot-scope="scope">
            <img v-if="scope.row.avatar"
                 :src="$url+scope.row.avatar"
                 min-width="70"
                 height="70" />
          </template>
        </el-table-column>
        <el-table-column prop="phone"
                         align="center"
                         label="手机号"
                         width="150">
        </el-table-column>
        <el-table-column prop="identity"
                         align="center"
                         :formatter="shenfen"
                         label="身份">
        </el-table-column>

        <el-table-column label="操作"
                         width="160">
          <template slot-scope="scope">
            <el-button v-if="authAttr>2"
                       type="danger"
                       size="mini"
                       @click="delAdmin(scope.$index,scope.row.id)">删除</el-button>
          </template>

        </el-table-column>

      </el-table>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <el-pagination background
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="queryParams.pageNum"
                         :page-sizes="[10, 20, 30, 50]"
                         :page-size="queryParams.pageSize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </el-row>
      </template>
    </el-card>
    <!-- 新增管理员 -->
    <template>
      <el-dialog title="添加管理员"
                 :visible.sync="xzdata"
                 width="35%">
        <div class="demo-input-suffix">
          <el-form :model="xzsj"
                   :rules="rules"
                   ref="xzsj"
                   label-width="80px">
            <el-form-item label="用户名"
                          prop="username">
              <el-input v-model="xzsj.username"
                        placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item label="密码"
                          prop="password">
              <el-input v-model="xzsj.password"
                        show-password
                        placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item label="身份"
                          prop="identity">
              <el-select v-model="xzsj.identity"
                         placeholder="请选择身份">
                <el-option v-for="item in identityList"
                           :key="item.id"
                           :label="item.role"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="continueAddFlag">继续添加</el-checkbox>
              <el-checkbox v-model="continuePasswordFlag">不清除密码和身份</el-checkbox>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="xzdata = false">取 消</el-button>
          <el-button type="primary"
                     @click="xzgly('xzsj')">添加</el-button>
        </span>
      </el-dialog>
    </template>

  </div>
</template>

<script>
export default {
  data() {
    return {
      czlsList: [],
      //查询管理员传参
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: '0'
      },
      total: 0,
      // 控制修改密码
      addDialogVisible: false,
      // 控制修改用户名
      alteruser: false,
      // 修改用户信息传参
      recompose: {
        type: '',
        oldPassword: '',
        newPassword: '',
        newUsername: '',
        avatar: ''
      },
      // 新增信息
      xzdata: false,
      // 新增传参
      xzsj: {
        username: '',
        password: '',
        identity: ''
      },
      identityList: [],
      authAttr: '',
      continueAddFlag: false,
      continuePasswordFlag: false,
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        identity: [{ required: true, message: '请选择身份', trigger: 'change' }]
      }
    }
  },
  mounted() {
    this.czls()
    this.getIdentityList()
    this.getAuthAttr()
  },
  methods: {
    async delAdmin(index, id) {
      const msg = await this.$confirm('你确定要删除该用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.post(`/admin/admin/delAdmin?id=${id}`)
        if (res.code != 200) return this.$message.error(res.msg)
        this.czlsList.splice(index, 1)
        this.$message.success('注销成功')
      }
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-5')
      this.authAttr = authAttr
    },
    async submitHandler() {
      this.recompose.type = 1
      console.log(this.recompose)
      const { data: res } = await this.$http.post(`/admin/admin/modifyAdminInfo?type=${this.recompose.type}&oldPassword=${this.recompose.oldPassword}&newPassword=${this.recompose.newPassword}`)
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.code == 200) return this.$message.success('密码修改成功')
      // if (this.formData.id) {
      //   var { data: res } = await this.$http.put('/com/xqqType', this.recompose)
      // } else {
      //   var { data: res } = await this.$http.post('/com/xqqType', this.recompose)
      // }
      // if (res.code !== 200) return this.$message.error(res.msg)
      // //刷新页面
      // this.getXqTypeList()
      //关闭弹窗
      this.addDialogVisible = false
    },
    // 新增管理员
    xzgly(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(`/admin/admin/registerAdmin?username=${this.xzsj.username}&password=${this.xzsj.password}&identity=${this.xzsj.identity}`)
          if (this.continueAddFlag) {
            if (this.continuePasswordFlag) {
              this.xzsj.username = ''
            } else {
              this.xzsj = {}
            }
          } else {
            this.xzdata = false
          }
          if (res.code !== 200) return this.$message.error(res.msg)
          if (res.code == 200) {
            if (this.continuePasswordFlag) {
              this.xzsj.username = ''
              this.czls()
              return this.$message.success('新增管理员成功')
            } else {
              this.xzsj = {}
              this.czls()
              return this.$message.success('新增管理员成功')
            }
          }
          //  关闭窗口

          // 刷新页面
        }
      })
    },
    // 修改用户名
    async submituser() {
      this.recompose.type = 2
      const { data: res } = await this.$http.post(`/admin/admin/modifyAdminInfo?type=${this.recompose.type}&newUsername=${this.recompose.newUsername}`)
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.code == 200) return this.$message.success('用户名修改成功')
      //关闭弹窗
      this.alteruser = false
    },
    async czls() {
      const { data: res } = await this.$http.get('/admin/admin/getAdminList', { params: this.queryParams })
      console.log(res)
      if (res.code != 200) return this.$message.error(res.msg)
      this.czlsList = res.data.list
      this.total = res.data.total
    },
    async getIdentityList() {
      const { data: res } = await this.$http.get('/admin/admin/getIdentityList')
      console.log(res)
      this.identityList = res.data
    },
    // 每页大小改变时
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.czls()
    },
    // 当前页码改变时触发
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.czls()
    },
    //
    // 身份
    shenfen(row) {
      if (row.identity == 1) {
        return '超级管理员'
      } else if (row.identity == 2) {
        return '产品管理员'
      } else if (row.identity == 3) {
        return '销售管理员'
      } else {
        return '销售'
      }
    },
    open4() {
      this.xzdata = true
    },
    // 修改状态
    async updateBrandStatus(row) {
      console.log(row)

      const { data: res } = await this.$http.post(`/admin/admin/setAdminStatus?id=${row.id}&status=${row.status}`)
      // this.czls()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>