<!-- 产品模块 -->
<template>
  <div class="hdgl">
    <!-- 头部搜索框 -->
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="78px">
      <el-form-item label="筛选条件">
        <el-select size="medium"
                   v-model="queryParams.status"
                   placeholder="请输入状态"
                   clearable>
          <el-option label="上架"
                     value="0"></el-option>
          <el-option label="下架"
                     value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>

        <el-button v-if="authAttr>1"
                   @click="addDialogVisible=true"
                   type="primary"
                   plain
                   size="medium"
                   icon="el-icon-plus">添加产品</el-button>

        <el-button v-if="authAttr>2"
                   @click="productout()"
                   type="danger"
                   plain
                   size="medium"
                   icon="el-icon-delete">清除失效产品</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="tableData"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="name"
                           align="center"
                           label="产品名"
                           width="180"></el-table-column>
          <el-table-column prop="shortName"
                           align="center"
                           label="产品名缩写"
                           width="160"></el-table-column>
          <el-table-column prop="shortIntroduce"
                           align="center"
                           label="产品简介"
                           width="180"></el-table-column>

          <el-table-column prop="cover"
                           align="center"
                           label="封面"
                           width="120">
            <template slot-scope="scope">
              <img v-if="scope.row.cover"
                   :src="$url+scope.row.cover"
                   min-width="70"
                   height="70"
                   alt="加载失败" />
            </template>
          </el-table-column>
          <el-table-column prop="price"
                           align="center"
                           label="价格"
                           width="100"></el-table-column>
          <el-table-column prop="status"
                           align="center"
                           width="150"
                           label="状态">
            <template slot-scope="scope">
              <span v-if="authAttr<=1">
                <span v-if="scope.row.status==0">已上架</span>
                <span v-if="scope.row.status==1">已下架</span>
              </span>
              <el-switch v-if="authAttr>1"
                         v-model="scope.row.status"
                         active-color="#13ce66"
                         inactive-color="#ff4949"
                         :active-value="0"
                         :inactive-value="1"
                         active-text="上架"
                         inactive-text="下架"
                         @change="updateBrandStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           width="180">
            <template v-slot="scope">
              <el-button type="text"
                         icon="el-icon-tickets"
                         @click="updateHandler(scope.row.id)">详情</el-button>
              <el-button v-if="authAttr>1"
                         type="text"
                         icon="el-icon-edit"
                         @click="updateContent(scope.row)">修改内容</el-button>
              <el-button v-if="authAttr>1"
                         type="text"
                         icon="el-icon-edit"
                         @click="updateImages(scope.row.id)">修改图片</el-button>
              <el-button v-if="authAttr>2"
                         type="text"
                         icon="el-icon-delete"
                         @click="delHandler(scope.$index,scope.row.id)">失效</el-button>
            </template>

          </el-table-column>
        </el-table>
      </template>

      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <el-pagination background
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="queryParams.pageNum"
                         :page-sizes="[10, 20, 30, 50]"
                         :page-size="queryParams.pageSize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </el-row>
      </template>
    </el-card>
    <!-- 添加产品对话框 -->
    <template>
      <el-dialog title="添加产品"
                 :visible.sync="addDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules"
                   label-width="80px">
            <el-form-item label="产品名称"
                          prop="name">
              <el-input v-model="recompose.name"
                        placeholder="请输入产品名称（必填）"></el-input>
            </el-form-item>
            <el-form-item label="产品简称"
                          prop="shortName">
              <el-input v-model="recompose.shortName"
                        placeholder="请输入产品简称"></el-input>
            </el-form-item>
            <el-form-item label="简介"
                          prop="shortIntroduce">
              <el-input v-model="recompose.shortIntroduce"
                        placeholder="请输入简介"></el-input>
            </el-form-item>
            <el-form-item label="状态"
                          prop="status">
              <el-select size="medium"
                         v-model="recompose.status"
                         placeholder="请输入状态">
                <el-option label="上架"
                           value="0"></el-option>
                <el-option label="下架"
                           value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="介绍">
              <ckeditor v-model="recompose.introduce"
                        :config="editorConfig"></ckeditor>

              <!-- <Editor v-model="recompose.introduce"
                      :value="recompose.introduce"></Editor> -->
            </el-form-item>
            <el-form-item label="价格"
                          prop="price">
              <el-input type="number"
                        v-model="recompose.price"
                        placeholder="请输入价格:单位元（必填）"></el-input>
            </el-form-item>
            <el-form-item label="类型"
                          prop="type">
              <el-input v-model="recompose.type"
                        placeholder="请输入类型"></el-input>
            </el-form-item>
            <el-form-item label="图片文件"
                          prop="images">
              <el-upload action="/api/admin/product/addProduct"
                         ref="upload"
                         :headers="headers"
                         multiple
                         :data='recompose'
                         :auto-upload="false"
                         :file-list="fileList"
                         list-type="picture-card"
                         :on-remove="handleRemove"
                         :on-success="hanlesucceed"
                         :on-change="hanlechange">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>

            <el-form-item label="封面"
                          prop="cover">
              <el-upload action="/api/admin/product/addProduct"
                         ref="upload2"
                         :headers="headers"
                         :limit="1"
                         :data='recompose'
                         :auto-upload="false"
                         list-type="picture-card"
                         :on-remove="handleRemoves"
                         :on-success="hanlesucceed"
                         :on-change="handleChange">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="continueAddFlag">继续添加</el-checkbox>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="success"
                     @click="submitHandler('recompose')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 详情 -->
    <template>
      <el-dialog title="详情信息"
                 :visible.sync="details"
                 width="50%">
        <el-form ref="form"
                 :model="xqlist"
                 label-width="80px">
          <el-form-item label="id"
                        prop="id">
            <el-input v-model="xqlist.id"
                      :disabled="true"
                      placeholder="请输入id" />
          </el-form-item>
          <el-form-item label="编号"
                        prop="number">
            <el-input v-model="xqlist.number"
                      :disabled="true"
                      placeholder="请输入编号" />
          </el-form-item>
          <el-form-item label="产品名"
                        prop="name">
            <el-input v-model="xqlist.name"
                      :disabled="true"
                      placeholder="请输入产品名" />
          </el-form-item>
          <el-form-item label="产品名缩写"
                        prop="shortName">
            <el-input v-model="xqlist.shortName"
                      :disabled="true"
                      placeholder="产品名缩写" />
          </el-form-item>
          <el-form-item label="产品简介"
                        prop="shortIntroduce">
            <el-input v-model="xqlist.shortIntroduce"
                      :disabled="true"
                      placeholder="请输入产品简介" />
          </el-form-item>
          <el-form-item label="产品详情"
                        prop="introduce">
            <el-input v-html="xqlist.introduce"
                      :disabled="true"
                      placeholder="请输入产品详情" />
          </el-form-item>
          <el-form-item label="产品封面"
                        prop="cover">
            <img :src="$url+xqlist.cover"
                 min-width="70"
                 height="70"
                 alt="加载失败">
          </el-form-item>

          <el-form-item label="产品图片"
                        prop="image">
            <img :src="$url+xqlist.images[index]"
                 min-width="70"
                 height="70"
                 alt="加载失败"
                 v-for="(item,index) in xqlist.images"
                 :key="index">
          </el-form-item>

          <el-form-item label="价格"
                        prop="price">
            <el-input v-model="xqlist.price"
                      :disabled="true"
                      placeholder="请输入价格" />
          </el-form-item>

          <el-form-item label="分类"
                        prop="type">
            <el-input v-model="xqlist.type"
                      :disabled="true"
                      placeholder="请输入分类" />
          </el-form-item>

          <el-form-item label="创建时间"
                        prop="createTime">
            <el-input v-model="xqlist.createTime"
                      :disabled="true"
                      placeholder="创建时间" />
          </el-form-item>
          <el-form-item label="修改时间"
                        prop="updateTime">
            <el-input v-model="xqlist.updateTime"
                      :disabled="true"
                      placeholder="修改时间" />
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="details = false">确 定</el-button>
        </span>
      </el-dialog>
    </template>
    <template>
      <el-dialog title="修改信息"
                 :visible.sync="alterProduct"
                 width="50%">
        <el-form ref="form"
                 :model="xqlist"
                 label-width="100px">
          <el-form-item label="id"
                        prop="id">
            <el-input v-model="xqlist.id"
                      :disabled="true"
                      placeholder="请输入id" />
          </el-form-item>
          <el-form-item label="编号"
                        prop="number">
            <el-input v-model="xqlist.number"
                      :disabled="true"
                      placeholder="请输入编号" />
          </el-form-item>
          <el-form-item label="产品名"
                        prop="name">
            <el-input v-model="xqlist.name"
                      placeholder="请输入产品名" />
          </el-form-item>
          <el-form-item label="产品名缩写"
                        prop="shortName">
            <el-input v-model="xqlist.shortName"
                      placeholder="产品名缩写" />
          </el-form-item>
          <el-form-item label="产品简介"
                        prop="shortIntroduce">
            <el-input v-model="xqlist.shortIntroduce"
                      placeholder="请输入产品简介" />
          </el-form-item>
          <el-form-item label="产品详情"
                        prop="introduce">
            <ckeditor v-model="xqlist.introduce"
                      :config="editorConfig"></ckeditor>
            <!-- <Editor ref="info"
                    v-model="xqlist.introduce"
                    :value="xqlist.introduce"></Editor> -->
          </el-form-item>
          <el-form-item label="价格"
                        prop="price">
            <el-input v-model="xqlist.price"
                      placeholder="请输入价格" />
          </el-form-item>
          <el-form-item label="分类"
                        prop="type">
            <el-input v-model="xqlist.type"
                      placeholder="请输入分类" />
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="alterProduct = false">取 消</el-button>
          <el-button type="primary"
                     @click="changexx">提 交</el-button>
        </span>
      </el-dialog>
    </template>
    <template>
      <el-dialog title="修改产品图片"
                 :visible.sync="alterProductImages"
                 width="50%">
        <p>修改图片为重新上传（封面和图片至少选择一个）</p><br>
        <div class="demo-input-suffix">
          <el-form ref="form"
                   label-width="80px">
            <el-form-item label="图片(多选)">
              <el-upload action="#"
                         ref="upload3"
                         :headers="headers"
                         multiple
                         :data='recompose'
                         :auto-upload="false"
                         :file-list="fileList"
                         list-type="picture-card"
                         :on-remove="handleRemove"
                         :on-success="hanlesucceed"
                         :on-change="hanlechange">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>
            <el-form-item label="封面(单选)">
              <el-upload action="#"
                         ref="upload4"
                         :headers="headers"
                         :limit="1"
                         :data='recompose'
                         :auto-upload="false"
                         list-type="picture-card"
                         :on-remove="handleRemove"
                         :on-success="hanlesucceed"
                         :on-change="handleChange">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="alterProductImages = false">取 消</el-button>
          <el-button type="success"
                     @click="updateArticleImages">提交</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue'
export default {
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      // 富文本编辑器的配置
      editorConfig: {},
      // 富文本编辑器的网址

      headers: {
        token: sessionStorage.getItem('token')
      },
      imageUrl: '',
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        status: ''
      },
      // 表格数据
      tableData: [],
      // 分类下拉列表数据
      flData: [],
      total: 0,
      addDialogVisible: false,
      alterProductImages: false,
      alterProduct: false,
      details: false,
      xqlist: {
        id: '',
        number: '',
        name: '',
        shortName: '',
        shortIntroduce: '',
        introduce: '',
        cover: '',
        List: '',
        images: '',
        price: '',
        type: '',
        createTime: '',
        updateTime: ''
      },
      //添加产品信息
      recompose: {
        shortName: '',
        name: '',
        shortIntroduce: '',
        status: '0',
        introduce: '',
        price: '',
        type: '',
        cover: null,
        images: null
      },
      dialogImageUrl: '',
      authAttr: '',
      fileList: [],
      cover: '',
      formData: new FormData(),
      productId: '',
      continueAddFlag: false,
      rules: {
        name: [
          { required: true, message: '请输入产品名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1到 50 个字符', trigger: 'blur' }
        ],
        shortName: [{ min: 1, max: 20, message: '长度在 1到 20 个字符', trigger: 'blur' }],
        shortIntroduce: [{ min: 0, max: 100, message: '长度在 0到 100 个字符', trigger: 'blur' }],
        price: [{ required: true, message: '请输入价格', trigger: 'change' }],
        images: [{ required: true, message: '请上传图片文件', trigger: 'change' }],
        cover: [{ required: true, message: '请上传封面', trigger: 'change' }]
      },
      hideCode: false,
      limitCount: 1,
      limitCode: 1,
      headFile: '',
      headFileList: '',
      codeFile: '',
      codeFileList: ''
    }
  },
  mounted() {
    this.getXqTypeList()
    this.getAuthAttr()
  },

  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getXqTypeList()
    },
    updateImages(id) {
      this.productId = id
      this.alterProductImages = true
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-4')
      this.authAttr = authAttr
    },
    // 查询表格数据
    async getXqTypeList() {
      const { data: res } = await this.$http.get('/admin/product/getProductList', { params: this.queryParams })
      console.log(res)
      if (res.code != 200) return this.$msgErr(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    //分类下拉列表
    async flList() {
      const { data: res } = await this.$http.get('/com/xqqType/list2')
      if (res.code != 200) return this.$msgErr(res.msg)
      this.flData = res.data
      console.log(this.flData)
    },
    // 每页大小改变时
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.getXqTypeList()
    },
    // 当前页码改变时触发
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getXqTypeList()
    },
    hanlechange(file, fileList) {
      this.fileList = fileList
      this.fileList.forEach((f) => {
        this.formData.set(f.name, f.raw)
      })

      this.codeFile = file
      this.codeFileList = fileList
      if (fileList.length == 1) {
        let { images, ...data } = this.rules
        this.rules = data
      }
      this.$refs.recompose.clearValidate('images')
      this.hideCode = fileList.length >= this.limitCode
    },
    handleChange(file, fileList) {
      this.cover = file.raw

      this.codeFile = file
      this.codeFileList = fileList
      console.log(this.codeFile)
      console.log(this.codeFileList)
      if (fileList.length == 1) {
        let { cover, ...data } = this.rules
        this.rules = data
        console.log(this.rules)
      }
      this.$refs.recompose.clearValidate('cover')
      console.log(this.$refs.recompose.clearValidate())
      this.hideCode = fileList.length >= this.limitCode
    },
    // 清除失效产品
    async productout() {
      const msg = await this.$confirm('你确定要清除所有失效产品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.get(`/admin/product/deleteProduct`)
        if (res.data > 0 && res.code == 200) {
          return this.$message.success('删除所有数据成功！')
        } else {
          return this.$message.warning('清除失败')
        }
        //删除成功了，刷新页面
        // this.getXqTypeList()
      }
    },
    //失效产品
    async delHandler(index, id) {
      const msg = await this.$confirm('你确定要失效该产品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.post(`/admin/product/invalidProduct?id=${id}`)
        if (res.code != 200) return this.$message.error(res.msg)
        this.tableData.splice(index, 1)
        //删除成功了，刷新页面
        // this.getXqTypeList()
      }
    },
    // 详情
    // `/admin/product/changeProductInfo?id=${_id}&name=${this.xqlist.name}&shortName=${this.xqlist.shortName}&shortIntroduce=${this.xqlist.shortIntroduce}&introduce=${this.xqlist.introduce}&price=${this.xqlist.price}&type=${this.xqlist.type}`
    async updateHandler(_id) {
      this.details = true
      const { data: res } = await this.$http.get(`/admin/product/getProductInfoById?id=${_id}`)
      //获取数据
      const { id, number, name, shortName, shortIntroduce, introduce, cover, List, images, price, type, createTime, updateTime } = res.data
      this.xqlist = { ...this.xqlist, id, number, name, shortName, shortIntroduce, introduce, cover, List, images, price, type, createTime, updateTime }
      this.xqlist.introduce = this.xqlist.introduce.replace(/\<img /gi, '<img class="rich_pages" ')
    },
    async updateContent(row) {
      this.alterProduct = true
      const { data: res } = await this.$http.get(`/admin/product/getProductInfoById?id=${row.id}`)
      this.xqlist = res.data
    },
    // 修改状态上架下架
    async updateBrandStatus(row) {
      console.log(row)
      const { data: res } = await this.$http.post(`/admin/product/setProductStatus?id=${row.id}&status=${row.status}`)
      this.getXqTypeList()
    },
    // 修改信息提交
    async changexx() {
      var fileFormData = new FormData()
      fileFormData.append('name', this.xqlist.name)
      if (this.xqlist.shortName != null) {
        fileFormData.append('shortName', this.xqlist.shortName)
      }
      fileFormData.append('shortIntroduce', this.xqlist.shortIntroduce)
      fileFormData.append('introduce', this.xqlist.introduce)
      fileFormData.append('type', this.xqlist.type)
      fileFormData.append('id', this.xqlist.id)
      fileFormData.append('price', this.xqlist.price)
      const { data: res } = await this.$http.post(`/admin/product/changeProductInfo`, fileFormData)
      this.alterProduct = false
      this.getXqTypeList()
    },
    // 添加产品提交
    submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let fileFormData = new FormData()
          this.fileList.forEach((f) => {
            fileFormData.append('images', f.raw)
          })
          fileFormData.append('cover', this.cover)
          fileFormData.append('name', this.recompose.name)
          fileFormData.append('shortName', this.recompose.shortName)
          fileFormData.append('shortIntroduce', this.recompose.shortIntroduce)
          fileFormData.append('introduce', this.recompose.introduce)
          fileFormData.append('status', this.recompose.status)
          fileFormData.append('price', this.recompose.price)
          fileFormData.append('type', this.recompose.type)
          if (!this.continueAddFlag) {
            this.addDialogVisible = false
          }
          this.recompose = {}
          this.rules.images = [{ required: true, message: '请上传图片文件', trigger: 'change' }]
          this.rules.cover = [{ required: true, message: '请上传封面', trigger: 'change' }]
          const { data: res } = await this.$http.post(`/admin/product/addProduct`, fileFormData)
          console.log(res)
          if (res.code == 200) {
            this.$message.success('添加成功')
            this.$refs.upload.clearFiles()
            this.$refs.upload2.clearFiles()

            this.cover = ''
            this.fileList = []
          } else {
            this.$message.error('添加失败')
            this.$refs.upload.clearFiles()
            this.$refs.upload2.clearFiles()
            this.cover = ''
            this.fileList = []
          }

          this.getXqTypeList()
        }
      })
    },
    async updateArticleImages() {
      let fileFormData = new FormData()
      this.fileList.forEach((f) => {
        fileFormData.append('images', f.raw)
      })
      fileFormData.append('cover', this.cover)
      fileFormData.append('id', this.productId)
      const { data: res } = await this.$http.post(`/admin/product/uploadProductImage`, fileFormData)
      if (res.code == 200) {
        this.$message.success('修改成功')
        this.$refs.upload3.clearFiles()
        this.$refs.upload4.clearFiles()
        this.recompose = {}
        this.cover = ''
        this.fileList = []
      } else {
        this.$message.error('修改失败')
        this.$refs.upload3.clearFiles()
        this.$refs.upload4.clearFiles()
        this.cover = ''
        this.fileList = []
      }
      this.getXqTypeList()
      this.alterProductImages = false
    },
    hanlesucceed(res, file) {},
    handleRemove(file, fileList) {
      if (fileList.length == 0) {
        this.rules.images = [{ required: true, message: '请上传图片文件', trigger: 'change' }]
      }
      this.hideUpload = fileList.length >= this.limitCount
    },
    handleRemoves(file, fileList) {
      if (fileList.length == 0) {
        this.rules.cover = [{ required: true, message: '请上传封面', trigger: 'change' }]
      }
      this.hideUpload = fileList.length >= this.limitCount
    },
    handlePreview(file) {
      console.log(file)
    }
  }
}
</script>
<style lang='scss' scoped>
.hdgl {
  font-size: 14px;
  overflow-x: hidden;
  padding: 0 18px;
  .search-bar {
    padding: 14px 0px;
    min-width: 990px;
  }

  .btns-curd {
    padding: 14px 0px;
  }

  .pagination {
    padding-top: 10px;
    text-align: right;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .rich_pages {
    width: 100% !important;
  }
}
</style>
<style>
.el-table__body,
.el-table__footer,
.el-table__header {
  width: 100% !important;
}
</style>