<!-- 推荐记录管理 -->
<template>
  <div>
    <el-form :model="queryParams"
             ref="queryForm"
             :inline="true"
             label-width="108px">
      <el-form-item label="用户名/手机号"
                    prop="nickName">
        <el-input v-model="queryParams.keyword"
                  placeholder="请输入用户名或手机号"
                  clearable
                  size="small" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   icon="el-icon-search"
                   size="mini"
                   @click="search()">搜索</el-button>

        <el-button type="success"
                   icon="el-icon-search"
                   size="mini"
                   @click="RecommendTree()">所有推荐</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData"
              align="center"
              style="width: 100%"
              :header-cell-style="{background: '#f8f8f9'}">

      <el-table-column label="用户名"
                       align="center"
                       prop="userName" />
      <el-table-column label="手机号"
                       align="center"
                       prop="phone" />
      <el-table-column label="积分"
                       align="center"
                       prop="score" />
      <el-table-column label="工时"
                       align="center"
                       prop="manHour" />
      <el-table-column label="期权"
                       align="center"
                       prop="option" />
      <el-table-column label="层级"
                       align="center"
                       prop="level" />
      <el-table-column label="操作"
                       align="center"
                       class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini"
                     type="text"
                     icon="el-icon-view"
                     @click="chakanxiaji(scope.row)">查看下级</el-button>

        </template>
      </el-table-column>
    </el-table>

    <!-- 分页导航 -->
    <template>
      <el-row class="pagination">
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="queryParams.pageNum"
                       :page-sizes="[10, 20, 30, 50]"
                       :page-size="queryParams.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </el-row>
    </template>

    <template>
      <el-dialog title="推荐列表"
                 :visible.sync="dialogTableVisible">
        <el-tree :data="data"
                 :props="defaultProps"
                 @node-click="handleNodeClick"></el-tree>
      </el-dialog>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      //推荐传参
      recommendList: {
        userId: ''
      },
      total: 0,
      tableData: [],
      dialogTableVisible: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'userName'
      }
    }
  },
  mounted() {
    this.getRecommendRecordList()
  },
  methods: {
    handleNodeClick(data) {
      console.log(data)
    },
    // 搜索
    search() {
      this.queryParams.pageNum = 1
      this.getRecommendRecordList()
    },
    // 查询表格数据
    async getRecommendRecordList() {
      const { data: res } = await this.$http.get('/admin/assets/getRecommendRecordList', { params: this.queryParams })
      console.log(res)
      if (res.code != 200) return this.$message.error(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    // 查看下机
    chakanxiaji(id) {
      this.recommendList.userId = id.userId
      this.dialogTableVisible = true
      this.getRecommendTree()
    },

    //查询所有推荐树
    RecommendTree() {
      this.recommendList.userId = ''
      this.dialogTableVisible = true
      this.getRecommendTree()
    },

    // 查询推荐树
    async getRecommendTree() {
      const { data: res } = await this.$http.get('/admin/assets/getRecommendTree', { params: this.recommendList })
      console.log(res)
      if (res.code != 200) return this.$message.error(res.msg)
      this.data = res.data
      console.log(this.data)
    },
    // 每页大小改变时
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.getRecommendRecordList()
    },
    // 当前页码改变时触发
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getRecommendRecordList()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>