<!-- 资产管理 -->
<template>
  <div>
    <el-form :model="queryParams"
             ref="queryForm"
             :inline="true"
             label-width="108px">
      <el-form-item label="用户名/手机号"
                    prop="nickName">
        <el-input v-model="queryParams.keyword"
                  placeholder="请输入用户名或手机号"
                  clearable
                  size="small" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   icon="el-icon-search"
                   size="mini"
                   @click="search()">搜索</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData"
              align="center"
              style="width: 100%"
              :header-cell-style="{background: '#f8f8f9'}">

      <el-table-column label="推荐人"
                       align="center"
                       prop="recommender" />
      <!--<el-table-column label="用户id" align="center" prop="uid" />-->
      <el-table-column label="被推荐人"
                       align="center"
                       prop="recommended" />
      <!--<el-table-column label="头像url" align="center" prop="txUrl" />-->
      <el-table-column label="手机号"
                       align="center"
                       prop="phone" />
      <el-table-column label="积分"
                       align="center"
                       prop="score" />
      <el-table-column label="工时"
                       align="center"
                       prop="manHour" />
      <el-table-column label="期权"
                       align="center"
                       prop="option" />
      <el-table-column label="层级"
                       align="center"
                       prop="level" />
      <el-table-column label="操作"
                       align="center"
                       class-name="small-padding fixed-width">
        <!-- <template slot-scope="scope">
          <el-button size="mini"
                     type="text"
                     icon="el-icon-view"
                     @click="chakanpinglun(scope.row)">查看评论</el-button>
          <el-button size="mini"
                     type="text"
                     icon="el-icon-edit"
                     @click="handleUpdate(scope.row)">修改</el-button>
          <el-button size="mini"
                     type="text"
                     icon="el-icon-delete"
                     @click="handleDelete(scope.row)">删除</el-button>
        </template> -->
      </el-table-column>
    </el-table>

    <!-- 分页导航 -->
    <template>
      <el-row class="pagination">
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="queryParams.pageNum"
                       :page-sizes="[10, 20, 30, 50]"
                       :page-size="queryParams.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </el-row>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: ''
      },
      total: 0,
      tableData: []
    }
  },
  mounted() {
    this.getAssetsList()
  },
  methods: {
    // 搜索
    search() {
      this.queryParams.pageNum = 1
      this.getAssetsList()
    },
    // 查询表格数据
    async getAssetsList() {
      const { data: res } = await this.$http.get('/admin/assets/getAssetsList', { params: this.queryParams })
      console.log(res)
      if (res.code != 200) return this.$message.error(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    // 每页大小改变时
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.getAssetsList()
    },
    // 当前页码改变时触发
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getAssetsList()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>