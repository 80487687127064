<!-- 文章模块 -->
<template>
  <div class="hdgl">
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="108px">
      <el-form-item label="筛选条件">
        <el-select v-model="queryParams.flag"
                   placeholder="">
          <el-option label="全部文章"
                     value="1"></el-option>
          <el-option label="我的文章"
                     value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="queryParams.type"
                   placeholder="请选择类型">
          <el-option label="所有类型"
                     value="0"></el-option>
          <el-option label="科普文章"
                     value="1"></el-option>
          <el-option label="活动"
                     value="2"></el-option>
          <el-option label="产品文章"
                     value="3"></el-option>
          <el-option label="专家"
                     value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="queryParams.top"
                   placeholder="请选择位置"
                   clearable>
          <el-option label="正常"
                     value="0"></el-option>
          <el-option label="首页轮播图"
                     value="1"></el-option>
          <el-option label="首页中心图"
                     value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="inquire"
                   type="primary">搜索</el-button>

        <el-button v-if="authAttr>1"
                   @click="addDialogVisible=true"
                   type="primary"
                   plain
                   size="medium"
                   icon="el-icon-plus">添加文章</el-button>

        <el-button v-if="authAttr>1"
                   @click="addExpertArticle()"
                   type="success"
                   plain
                   size="medium"
                   icon="el-icon-plus">添加专家</el-button>
      </el-form-item>
    </el-form>

    <el-card class="box-card">
      <!-- (CURD)操作按钮 -->

      <!-- 数据表格 -->
      <template>
        <el-table :data="tableData"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="title"
                           align="center"
                           label="标题"
                           width="180"></el-table-column>
          <el-table-column prop="cover"
                           align="center"
                           label="封面"
                           width="120">
            <template slot-scope="scope">
              <img :src="$url+scope.row.cover"
                   min-width="70"
                   height="70"
                   alt="加载失败" />
            </template>
          </el-table-column>
          <el-table-column prop="status"
                           align="center"
                           width="220"
                           label="状态">

            <template slot-scope="scope">
              <span v-if="authAttr<=1">
                <span v-if="scope.row.status==0">正常</span>
                <span v-if="scope.row.status==1">关闭</span>
              </span>
              <el-switch v-if="authAttr>1"
                         v-model="scope.row.status"
                         active-color="#13ce66"
                         inactive-color="#ff4949"
                         :active-value="0"
                         :inactive-value="1"
                         active-text="正常"
                         inactive-text="关闭"
                         @change="updateBrandStatus(scope.row)"></el-switch>
            </template>

          </el-table-column>
          <el-table-column prop="type"
                           align="center"
                           :formatter="lx"
                           label="类型"></el-table-column>
          <el-table-column prop="releaseName"
                           align="center"
                           label="发布者"></el-table-column>
          <el-table-column prop="top"
                           align="center"
                           :formatter="dz"
                           width="100"
                           label="顶置">
          </el-table-column>
          <el-table-column label="操作"
                           width="180">
            <template v-slot="scope">
              <el-button type="text"
                         icon="el-icon-tickets"
                         @click="delHandler(scope.row.id)">详情</el-button>
              <el-button type="text"
                         v-if="authAttr>1"
                         icon="el-icon-edit"
                         @click="updateArticle(scope.row)">修改内容</el-button>
              <el-button type="text"
                         v-if="authAttr>1"
                         icon="el-icon-delete"
                         @click="deleteArticle(scope.$index,scope.row.id)">删除</el-button>
              <el-button type="text"
                         v-if="authAttr>1"
                         icon="el-icon-edit"
                         @click="updateArticleCover(scope.row.id)">修改封面</el-button>

            </template>

          </el-table-column>
        </el-table>
      </template>

      <!-- 详情 -->
      <template>
        <el-dialog title="详情信息"
                   :visible.sync="details"
                   width="60%">
          <div class="demo-input-suffix">
            <el-form ref="form"
                     label-width="80px">
              <!-- :model="form" -->
              <el-form-item label="ID">
                <el-input v-model="xqlist.id"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="标题">
                <el-input v-model="xqlist.title"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="内容">
                <v-text v-html="xqlist.content"
                        class="image1"></v-text>

              </el-form-item>
              <el-form-item label="类型">
                <el-input v-bind:value="lx(xqlist)"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="封面">
                <img :src="$url+xqlist.cover"
                     min-width="70"
                     height="70"
                     alt="加载失败">
              </el-form-item>
              <el-form-item label="状态">
                <el-input v-bind:value="st(xqlist)"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="发布者">
                <el-input v-model="xqlist.releaseName"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="顶置">
                <el-input v-bind:value="dz(xqlist)"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="创建时间">
                <el-input v-model="xqlist.createTime"
                          :disabled="true">
                </el-input>
              </el-form-item>
              <el-form-item label="修改时间">
                <el-input v-model="xqlist.updateTime"
                          :disabled="true">
                </el-input>
              </el-form-item>

            </el-form>
          </div>
          <span slot="footer"
                class="dialog-footer">
            <el-button type="primary"
                       @click="details = false">确 定</el-button>
          </span>
        </el-dialog>
      </template>

      <!-- 修改 -->
      <template>
        <el-dialog title="修改信息"
                   :visible.sync="alterArticle"
                   width="60%"
                   @close="reset">
          <div class="demo-input-suffix">
            <el-form ref="form"
                     label-width="80px">
              <el-form-item label="id">
                <el-input disabled
                          v-model="xqlist.id">
                </el-input>
              </el-form-item>
              <!-- :model="form" -->
              <el-form-item label="标题">
                <el-input placeholder="请输入标题"
                          v-model="xqlist.title">
                </el-input>
              </el-form-item>
              <el-form-item label="内容">
                <ckeditor v-model="xqlist.content"
                          :config="editorConfig"></ckeditor>
              </el-form-item>
              <el-form-item label="类型">
                <el-select v-model="xqlist.type"
                           placeholder="请选择类型">
                  <el-option label="科普文章"
                             value="1"></el-option>
                  <el-option label="活动"
                             value="2"></el-option>
                  <el-option label="产品文章"
                             value="3"></el-option>
                  <el-option label="专家"
                             value="4"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="xqlist.type!=4"
                            label="位置">
                <el-select v-model="xqlist.top"
                           placeholder="顶置">
                  <el-option label="正常"
                             value="0"></el-option>
                  <el-option label="首页轮播图"
                             value="1"></el-option>
                  <el-option label="首页中心图"
                             value="2"></el-option>
                </el-select>
              </el-form-item>

            </el-form>
          </div>
          <span slot="footer"
                class="dialog-footer">
            <el-button type="primary"
                       @click="updateHandler">提 交</el-button>
          </span>
        </el-dialog>
      </template>

      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <el-pagination background
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="queryParams.pageNum"
                         :page-sizes="[10, 20, 30, 50]"
                         :page-size="queryParams.pageSize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </el-row>
      </template>
    </el-card>
    <!-- 新增对话框 -->
    <template>
      <el-dialog title="添加文章"
                 :visible.sync="addDialogVisible"
                 width="60%">
        <div class="demo-input-suffix">
          <el-form :model="appenddata"
                   ref="appenddata"
                   :rules="rules"
                   label-width="80px">
            <!-- :model="form" -->
            <el-form-item label="标题"
                          prop="title">
              <el-input v-model="appenddata.title"
                        placeholder="请输入标题"></el-input>
            </el-form-item>
            <el-form-item label="内容">
              <ckeditor v-model="appenddata.content"
                        :config="editorConfig"></ckeditor>

            </el-form-item>
            <!-- <el-form-item label="封面">
              <el-input v-model="appenddata.cover"
                        placeholder="标题"></el-input>
            </el-form-item> -->
            <el-form-item label="类型"
                          prop="type">
              <el-select v-model="appenddata.type"
                         placeholder="请选择类型">
                <el-option label="科普文章"
                           value="1"></el-option>
                <el-option label="活动"
                           value="2"></el-option>
                <el-option label="产品文章"
                           value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="位置"
                          prop="top">
              <el-select v-model="appenddata.top"
                         placeholder="请选择位置">
                <el-option label="正常"
                           value="0"></el-option>
                <el-option label="轮播图"
                           value="1"></el-option>
                <el-option label="中心图"
                           value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态"
                          prop="status">
              <el-select v-model="appenddata.status"
                         placeholder="请选择状态">
                <el-option label="正常"
                           value="0"></el-option>
                <el-option label="关闭"
                           value="1"></el-option>

              </el-select>
            </el-form-item>
            <el-form-item label="封面pic"
                          prop="cover">
              <el-upload action="/api/admin/article/addArticle"
                         ref="upload"
                         :headers="headers"
                         name="cover"
                         :limit="1"
                         :data='appenddata'
                         :auto-upload="false"
                         list-type="picture-card"
                         :on-preview="handlePictureCardPreview"
                         :on-remove="handleRemoves"
                         :on-success="hanlesucceed"
                         :on-change="hanlechanges">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%"
                     :src="dialogImageUrl"
                     alt="">
              </el-dialog>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="continueAddFlag">继续添加</el-checkbox>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="danger"
                     @click="reset">重置</el-button>
          <el-button type="success"
                     @click="submitHandler('appenddata')">添加</el-button>
        </span>
      </el-dialog>
    </template>
    <template>
      <el-dialog title="添加专家"
                 :visible.sync="addExpertFlag"
                 width="60%">
        <div class="demo-input-suffix">
          <el-form :model="appenddata2"
                   ref="appenddata2"
                   :rules="rules"
                   label-width="120px">
            <!-- :model="form" -->
            <el-form-item label="专家名称"
                          prop="name">
              <el-input v-model="appenddata2.title"
                        placeholder="请输入专家姓名"></el-input>
            </el-form-item>
            <el-form-item label="专家介绍">
              <ckeditor v-model="appenddata2.content"
                        :config="editorConfig"></ckeditor>
            </el-form-item>
            <el-form-item label="专家照片"
                          prop="image">
              <el-upload action="/api/admin/article/addArticle"
                         ref="uploadExpect"
                         :headers="headers"
                         name="cover"
                         :data='appenddata2'
                         :auto-upload="false"
                         :limit="1"
                         list-type="picture-card"
                         :on-preview="handlePictureCardPreview"
                         :on-remove="handleRemove"
                         :on-success="hanlesucceed"
                         :on-change="hanlechange">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%"
                     :src="dialogImageUrl"
                     alt="">
              </el-dialog>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="continueAddFlag">继续添加</el-checkbox>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addExpertFlag = false">取 消</el-button>
          <el-button type="success"
                     @click="submitExpect('appenddata2')">添加</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- 修改封面 -->
    <template>
      <el-dialog title="修改文章封面"
                 :visible.sync="alterArticleCover"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form ref="form"
                   label-width="80px">
            <el-form-item label="封面">
              <el-upload action="/api/admin/article/updateArticle"
                         ref="uploadCover"
                         :headers="headers"
                         name="cover"
                         :data='appenddata'
                         :auto-upload="false"
                         list-type="picture-card">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%"
                     :src="dialogImageUrl"
                     alt="">
              </el-dialog>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="alterArticleCover = false">取 消</el-button>
          <el-button type="success"
                     @click="uploadAtricleCover">确认修改</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
var token = localStorage.getItem('token')
import CKEditor from 'ckeditor4-vue'

export default {
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      // 富文本编辑器的配置
      editorConfig: {},
      // 富文本编辑器的网址
      headers: {
        token: sessionStorage.getItem('token')
      },
      // 图片上传
      dialogImageUrl: '',
      dialogVisible: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        flag: '1',
        top: '',
        type: '0'
      },
      tableData: [],
      total: 0,
      addDialogVisible: false,
      details: false,
      alterArticle: false,
      xqlist: {},
      // 新增传参
      appenddata: {
        title: '',
        content: '',
        type: '',
        top: '',
        status: ''
      },
      appenddata2: {
        title: '',
        content: '',
        type: '',
        top: '',
        status: ''
      },
      authAttr: '',
      value: '',
      expectValue: '',
      alterArticleCover: false,
      addExpertFlag: false,
      continueAddFlag: false,
      hideCode: false,
      limitCount: 1,
      limitCode: 1,
      headFile: '',
      headFileList: '',
      codeFile: '',
      codeFileList: '',
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1到 50 个字符', trigger: 'blur' }
        ],
        cover: [{ required: true, message: '请上传封面', trigger: 'change' }],
        top: [{ required: true, message: '请选择位置', trigger: 'change' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        name: [
          { required: true, message: '请输入专家名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1到 50 个字符', trigger: 'blur' }
        ],
        // content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        image: [{ required: true, message: '请上传专家图片', trigger: 'change' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }]
      }
    }
  },
  mounted() {
    this.getXqTypeList()
    this.getAuthAttr()
  },
  methods: {
    // questiononblur() {
    //   console.log(this.$refs.appenddata2.validateField('content'))
    // },
    //监听富文本内容的改变（获取数据）

    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getXqTypeList()
    },
    addExpertArticle() {
      this.addExpertFlag = true
      this.appenddata2.type = 4
      this.appenddata2.status = 0
      this.appenddata2.top = 1
    },
    updateArticleCover(id) {
      this.alterArticleCover = true
      this.appenddata.id = id
      this.appenddata.flag = 4
    },
    uploadAtricleCover() {
      this.$refs.uploadCover.submit()
      this.$refs.uploadCover.clearFiles()
      this.alterArticleCover = false
      this.getXqTypeList()
    },
    async updateArticle(row) {
      this.alterArticle = true
      const { data: res } = await this.$http.get(`/admin/article/getArticleInfoById?id=${row.id}`)
      this.xqlist = res.data
      this.xqlist.type = this.xqlist.type + ''
      this.xqlist.top = this.xqlist.top + ''
    },
    getAuthAttr() {
      this.authAttr = window.sessionStorage.getItem('auth-1')
    },
    // 专家
    handleRemove(file, fileList) {
      if (fileList.length == 0) {
        this.rules.image = [{ required: true, message: '请上传专家图片', trigger: 'change' }]
      }
      this.hideUpload = fileList.length >= this.limitCount
    },
    // 添加文章
    handleRemoves(file, fileList) {
      if (fileList.length == 0) {
        this.rules.cover = [{ required: true, message: '请上传封面', trigger: 'change' }]
      }
      this.hideUpload = fileList.length >= this.limitCount
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    hanlesucceed(response, file, fileList) {
      this.$message.success('新增文章成功')
    },
    // 专家
    hanlechange(file, fileList) {
      this.codeFile = file
      this.codeFileList = fileList
      if (fileList.length == 1) {
        let { image, ...data } = this.rules
        this.rules = data
      }
      this.$refs.appenddata2.clearValidate('image')
      this.hideCode = fileList.length >= this.limitCode
    },
    // 添加文章
    hanlechanges(file, fileList) {
      this.codeFile = file
      this.codeFileList = fileList
      if (fileList.length == 1) {
        let { cover, ...data } = this.rules
        this.rules = data
      }
      this.$refs.appenddata.clearValidate('cover')
      this.hideCode = fileList.length >= this.limitCode
    },
    // 查询表格数据
    async getXqTypeList() {
      const { data: res } = await this.$http.get('/admin/article/getArticleList', { params: this.queryParams })
      console.log(res)
      if (res.code != 200) return this.$msgErr(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    // 每页大小改变时
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.getXqTypeList()
    },
    // 当前页码改变时触发
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getXqTypeList()
    },
    // 添加文章提交
    async submitHandler(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          //把富文本的内容给tableData
          // this.appenddata.content = this.value
          this.$refs.upload.submit()
          this.$refs.upload.clearFiles()
          this.reset()
          this.rules.cover = [{ required: true, message: '请上传封面', trigger: 'change' }]
          //刷新页面
          if (!this.continueAddFlag) {
            //关闭弹窗
            this.addDialogVisible = false
            //富文本清空
          }
          this.getXqTypeList()
          this.editor.children[0].innerHTML = ''
        }
      })
    },
    // 添加专家提交
    submitExpect(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          //把富文本的内容给tableData
          // this.appenddata2.content = this.expectValue
          this.$refs.uploadExpect.submit()
          this.$refs.uploadExpect.clearFiles()
          this.rules.image = [{ required: true, message: '请上传专家图片', trigger: 'change' }]
          if (!this.continueAddFlag) {
            //关闭弹窗
            this.addExpertFlag = false
          }
          this.appenddata2.title = ''
          this.$refs.editorExpect.html = ''
          this.expectValue = ''
          //富文本清空

          //刷新页面
          this.getXqTypeList()
        }
      })
    },
    // 修改
    async updateHandler() {
      this.alterArticle = false
      var formData = new FormData()
      formData.append('id', this.xqlist.id)
      formData.append('flag', 3)
      formData.append('title', this.xqlist.title)
      formData.append('content', this.xqlist.content)
      formData.append('type', this.xqlist.type)
      formData.append('top', this.xqlist.top)
      const res = await this.$http.post(`/admin/article/updateArticle`, formData)
      this.getXqTypeList()
      if (res.data.code == 200) return this.$message.success('修改成功')
    },
    //详情
    async delHandler(id) {
      this.details = true
      const { data: res } = await this.$http.get(`/admin/article/getArticleInfoById?id=${id}`)
      console.log(res)
      this.xqlist = res.data
      this.xqlist.content = this.xqlist.content.replace(/\<img /gi, '<img class="rich_pages" ')
    },
    async deleteArticle(index, id) {
      const msg = await this.$confirm('是否确认删除该文章', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.post(`/admin/article/delArticleById?id=${id}`)
        this.tableData.splice(index, 1)
      }
    },
    // 修改状态
    async updateBrandStatus(row) {
      console.log(row)

      const { data: res } = await this.$http.post(`/admin/article/updateArticle?flag=2&id=${row.id}&status=${row.status}`)
      this.getXqTypeList()
    },
    reset() {
      this.appenddata = {}
      this.value = ''
    },
    lx(row) {
      if (row.type == 1) {
        return '科普文章'
      } else if (row.type == 2) {
        return '活动'
      } else if (row.type == 3) {
        return '产品文章'
      } else if (row.type == 4) {
        return '专家'
      }
    },
    dz(row) {
      if (row.top == 0) {
        return '正常'
      } else if (row.top == 1) {
        return '首页轮播图'
      } else {
        return '首页中心图'
      }
    },
    st(row) {
      if (row.status == 0) {
        return '正常'
      } else if (row.status == 1) {
        return '关闭'
      }
    }
  }
}
</script>
<style lang='scss' >
.hdgl {
  font-size: 14px;
  overflow-x: hidden;
  padding: 0 18px;
  .search-bar {
    padding: 14px 0px;
    min-width: 990px;
  }

  .btns-curd {
    padding: 14px 0px;
  }

  .pagination {
    padding-top: 10px;
    text-align: right;
  }
  .rich_pages {
    width: 100% !important;
  }
}
</style>